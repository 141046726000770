import { TFT_SET_13 } from '~/data/sets';
import { getConventionalRegionName } from '~/helpers/getConventionalRegionName';
import { NavItem } from '~/types/nav';
import { RiotPlatformRegion } from '~/types/riot';

export const navList: NavItem[] = [
  {
    name: 'Home',
    to: { name: getPageNames().ROUTE_HOME_NAME },
  },
  {
    name: 'Stats',
    to: { name: getPageNames().ROUTE_STATS_COMPS_NAME },
    isDropdownMenu: true,
    matchPath: '/stats/',
    subItems: [
      {
        name: 'Explorer',
        imageUrl: getTFTChampionTileUrl('TFT13_Silco'),
        to: { name: getPageNames().ROUTE_STATS_EXPLORER_NAME },
        beta: true,
        blurb:
            'Time to sink yourself into the data and find the edge in your games!',
        isDropdownItem: true,
      },
      {
        name: 'Comps',
        imageUrl: getTFTChampionTileUrl('TFT13_Jayce'),
        to: { name: getPageNames().ROUTE_STATS_COMPS_NAME },
        blurb:
            'How is your favourite comp stacking up against the competition?',
        isDropdownItem: true,
      },
      {
        name: 'Champions',
        imageUrl: getTFTChampionTileUrl('TFT13_Gremlin'),
        to: { name: getPageNames().ROUTE_STATS_CHAMPIONS_NAME },
        blurb:
            'Which champion has the best average placement? Time to find out.',
        isDropdownItem: true,
      },
      {
        name: 'Items',
        imageUrl: getTFTItemUrl('TFT13_Crime_Silver_DestabilizedChemtank', 'TFTSet13'),
        to: { name: getPageNames().ROUTE_STATS_ITEMS_NAME },
        blurb: 'Use your components wisely, know which Items perform the best!',
        isDropdownItem: true,
      },
      {
        name: 'Traits',
        imageUrl: getTFTTraitUrl('TFT13_Rebel'),
        to: { name: getPageNames().ROUTE_STATS_TRAITS_NAME },
        blurb:
            'Is it worth leveling up to hit your next Trait tier? We know the answer.',
        imageSize: '2.2rem',
        isDropdownItem: true,
      },
      // Removed as of Set 13
      // {
      //   name: 'Augments',
      //   imageUrl: getTFTAugmentUrl('TFT_Augment_DarkAlleyDealings', 'TFTSet12'),
      //   to: { name: getPageNames().ROUTE_STATS_AUGMENTS_NAME },
      //   blurb:
      //       'Are your favourite Augments really helping or hurting you? Explore the stats here!',
      //   imageSize: '2.4rem',
      //   isDropdownItem: true,
      // },
    ],
  },
  {
    name: 'Leaderboard',
    to: {
      name: getPageNames().ROUTE_LEADERBOARD_NAME,
      params: {
        queueType: 'ranked',
        region: getConventionalRegionName(RiotPlatformRegion.GLOBAL),
      },
    },
    isDropdownMenu: true,
    matchPath: '/leaderboard/',
    subItems: [
      {
        name: 'Ranked Leaderboard',
        imageUrl: getTFTChampionTileUrl('TFT13_Trundle'),
        to: {
          name: getPageNames().ROUTE_LEADERBOARD_NAME,
          params: {
            queueType: 'ranked',
            region: getConventionalRegionName(RiotPlatformRegion.GLOBAL),
          },
        },
        blurb: 'Dominating ranked? See how high you can climb the leaderboard!',
        isDropdownItem: true,
      },
      {
        name: 'Top Players',
        imageUrl: getTFTChampionTileUrl('TFT13_Ambessa'),
        beta: true,
        to: {
          name: getPageNames().ROUTE_LEADERBOARD_TOP_NAME,
          params: {
            queueType: 'ranked',
            region: getConventionalRegionName(RiotPlatformRegion.GLOBAL),
          },
        },
        blurb:
            "Who are the best players of the set? Here's who has held Rank 1 or Top 10 the longest!",
        isDropdownItem: true,
      },
    ],
  },
  {
    name: 'Database',
    to: getChampionPageLink({ set: TFT_SET_13, championName: 'amumu' }),
    isDropdownMenu: true,
    matchPath: '/database/',
    subItems: [
      {
        name: 'Champions',
        imageUrl: getTFTChampionTileUrl('TFT13_Caitlyn'),
        to: getChampionPageLink({ set: TFT_SET_13, championName: 'amumu' }),
        blurb:
            'Champions are at the core of TFT, make sure you know who they are!',
        isDropdownItem: true,
      },
      {
        name: 'Items',
        imageUrl: getTFTItemUrl('TFT_Item_Crownguard', 'TFTSet13'),
        to: getItemPageLink({ set: TFT_SET_13, itemName: 'adaptive-helm' }),
        blurb:
            'Items can turn a weak Champion into a beast, find the best to use here!',
        isDropdownItem: true,
      },
      {
        name: 'Augments',
        imageUrl: getTFTAugmentUrl('TFT13_Augment_ChainsawCarry', 'TFTSet13'),
        to: getAugmentPageLink({ set: TFT_SET_13, augmentName: 'backup' }),
        blurb:
            'The right Augment can be the difference between 1st and 8th, check them all out here!',
        imageSize: '2.4rem',
        isDropdownItem: true,
      }
    ],
  },
];
