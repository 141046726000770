<template>
  <div :id="divId"/>
</template>

<script lang="ts" setup>
interface Props {
  adUnitPath: string;
  divId: string;
}

const props = defineProps<Props>();

const adSlot: googletag.Slot | null = ref(null);

onMounted(() => {
  window.googletag = window.googletag || { cmd: [] };

  googletag.cmd.push(function () {
    adSlot.value = googletag
        .defineSlot(props.adUnitPath, [1, 1], props.divId)
        .addService(googletag.pubads());


    googletag.pubads().enableSingleRequest();
    googletag.enableServices();
  });

  googletag.cmd.push(function () {
    if (googletag.pubads().isInitialLoadDisabled()) {
      googletag.display(props.divId);
      if (adSlot.value) googletag.pubads().refresh([adSlot.value]);
    } else {
      googletag.display(props.divId);
    }
  });
});
</script>